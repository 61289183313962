import restaurant from "./images/rest.jpg";
import img_card from "./images/img1.jpg";
import imgheaderrestaurant from "./images/headerrestaurant.jpg";
import imgheader from "./images/header.webp";
import imgheaderm from "./images/headermb.webp";
import img2 from "./images/img1.jpg";
import imgheadermiini from "./images/header.jpg";
import imgheadermiinimb from "./images/header.jpg";

{/*valeriacosarca@gmail.com*/}

const data = {
    introData: [{
        title: "Invitatie Online",
        mire: "Constantin",
        mireasa: "Violeta",
        data: "9 Iunie 2023",
        data_confirmare: "25 mai 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheaderm,
        email: "constatinhincu@gmail.com", 
       tel: "+33755237369",
       phone: "tel:+33755237369",
       viber: "viber://chat?number=%2B33755237369",
       whatsapp: "https://wa.me/+33755237369",
       messenger: "https://www.messenger.com/t/",
       tel1: "+373xxx",
       phone1: "tel:+373xxx",
       viber1: "viber://chat?number=%2B373xxx",
       whatsapp1: "https://wa.me/+373xxx",
       messenger1: "https://www.messenger.com/t/iurii.denis",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "Locația Evenimentului",
            name: "Chez Omenashou",
            data: "9 iunie 2023, vineri, ora 19:00",
            adress: "10 Rue Léonard de Vinci, 77170 Brie-Comte-Robert, France",
            harta: "https://goo.gl/maps/YuYbFSKwxu5hirFr9",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10532.620334090007!2d2.6047384!3d48.7025167!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e609f51476f395%3A0x1ff365a85f763ce3!2sChez%20Omenashou%202!5e0!3m2!1sro!2s!4v1682082084533!5m2!1sro!2s"
        }

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Ne căsătorim!",
            title2: "VĂ INVITĂM LA NUNTA NOASTRĂ!",
            message: "În ziua în care ne vom uni destinele, vrem să fim inconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
            ps: "",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
             
        }
    ],
    blogDataOmSuflet: [
        {

            img: imgheaderrestaurant,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "De aici începe totul.",
            message: "\"Dragostea constă în dorința de a da ceea ce este al tău altuia și de a simți fericirea acestuia ca și cum ar fi a ta. \" – Emanuel Swdenborg",
        }
    ],

}

export default data;